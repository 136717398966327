import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { ReactComponent as ChatIcon } from '@ui/assets/icons/chat.svg';
import Button from '@ui/components/Button/Button';
import Link from '@ui/components/Link/Link';
import Paragraph from '@ui/components/Paragraph/Paragraph';
import Title, { Heading } from '@ui/components/Title/Title';
import { centerContent, createTypography } from '@ui/styles/base';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';
import SEO from '../SEO/SEO';

interface Props {
  callToActionHref?: string;
  callToActionText?: string;
  hideAction?: boolean;
  subtitle: string;
  title: string;
}

const Container = styled.div(({ theme: { colours, typography } }) => [
  createTypography(typography.body01),
  centerContent,
  css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colours.background.main};
  `,
]);

const Content = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 ${spacings['16']}px;
    margin: auto;
  `,
);

const StyledTitle = styled(Title)(({ theme: { colours, typography } }) => [
  createTypography(typography.heading01),
  css`
    ${Heading} {
      color: ${colours.brand.primary};
      line-height: initial;
      text-align: center;
    }
  `,
]);

const TopContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacings['16']}px;
  `,
);

const BottomContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    width: 70%;
    flex-direction: column;
    align-self: center;
    margin-top: ${spacings['24']}px;
  `,
);

const StyledParagraph = styled(Paragraph)(() => [
  css`
    padding-top: 0;
    text-align: center;
  `,
]);

const TitleContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
);

const StyledLink = styled(Link)(
  ({ theme: { spacings } }) => css`
    width: 100%;
    max-width: 200px;
    align-self: center;
    margin-top: ${spacings['24']}px;
  `,
);

const BoldDiv = styled.div`
  font-weight: bold;
`;

const StyledChatIcon = styled(ChatIcon)(
  ({ theme: { spacings } }) => css`
    width: 15px;
    height: 15px;
    margin-right: ${spacings['16']}px;
  `,
);

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
`;

const StyledContent = styled.div(
  ({ theme: { spacings } }) => css`
    padding-left: calc(15px + ${spacings['16']}px);
  `,
);

const ErrorPage = ({
  callToActionHref,
  callToActionText,
  hideAction,
  subtitle,
  title,
}: Props) => {
  const { t } = useTranslation();
  const {
    helpCenter: { email, supportLink },
  } = usePartnerInfo();

  return (
    <>
      <SEO hreflangs={[]} isIndexed={false} title={title} />
      <Container>
        <Content>
          <TopContainer>
            <TitleContainer>
              <StyledTitle>{t('Oops!')}</StyledTitle>
              <StyledTitle>{title}</StyledTitle>
            </TitleContainer>
            <StyledParagraph>{subtitle}</StyledParagraph>
            {!hideAction && (
              <StyledLink href={callToActionHref || '/'} target="self">
                <Button>{callToActionText || t('Front page')}</Button>
              </StyledLink>
            )}
          </TopContainer>
          <BottomContainer>
            <StyledHeading>
              <StyledChatIcon />
              <BoldDiv>{t('How to get in touch with us?')}</BoldDiv>
            </StyledHeading>
            <StyledContent>
              <Trans
                components={[
                  <Link href={supportLink} key={supportLink} target="_blank">
                    {t('Please visit our support page')}
                  </Link>,
                  <Link href={`mailto: ${email}`} key="service-email-link">
                    {email}
                  </Link>,
                ]}
                i18nKey="Please visit our support page"
                values={{
                  email,
                }}
              />
            </StyledContent>
          </BottomContainer>
        </Content>
      </Container>
    </>
  );
};

export default ErrorPage;
