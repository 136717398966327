import useTranslation from 'next-translate/useTranslation';
import { useGetPartnerQuery } from '@codegen/cmsUtils';
import { Partner } from '@shared/types/enums';
import ErrorPage from '@web/components/Errors/ErrorPage';
import HeadTags from '@web/components/HeadTags';
import PageLayout from '@web/components/PageLayout';
import { AnalyticsProvider } from '@web/context/AnalyticsContext';
import ApplicationStringProvider from '@web/context/ApplicationStringProvider';
import { ConstantProvider } from '@web/context/ConstantContext';
import { SettingsProvider } from '@web/context/SettingsContext';
import { ThemeProvider } from '@web/context/ThemeContext';
import { mapHostNameToPartner } from '@web/utils/hostnameUtils';

const Custom404 = () => {
  const { t } = useTranslation();

  const partner = mapHostNameToPartner(
    typeof window !== 'undefined' ? window.location.hostname : undefined,
  );

  const { isLoading } = useGetPartnerQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  if (isLoading) {
    return null;
  }

  return (
    <ConstantProvider partner={partner as Partner}>
      <SettingsProvider>
        <main className="app">
          <AnalyticsProvider>
            <ApplicationStringProvider>
              <ThemeProvider>
                <HeadTags />
                <PageLayout>
                  <ErrorPage
                    subtitle={t(
                      'We cant find the page you are looking for but we suggest going back',
                    )}
                    title={t('That was unexpected')}
                  />
                </PageLayout>
              </ThemeProvider>
            </ApplicationStringProvider>
          </AnalyticsProvider>
        </main>
      </SettingsProvider>
    </ConstantProvider>
  );
};

export default Custom404;
